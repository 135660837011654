<template>
  <div class="request-author">
    <Popup
      class="popup"
      custom-class="gray-header"
      ref="popupExampleRef"
      modal-id="add-author"
      :on-close="onModalClose"
      size="lg"
      :toggle-status="toggleStatus"
    >
      <template v-slot:header> {{ labels.manage_authors }} </template>
      <template v-slot:body>
        <div v-if="modulePageData.tabsContentCanBeShown" class="row h-100">
          <div class="request-author__list h-100 mb-4 mb-md-0 col-12 col-md-6">
            <TableOverview
              kind="user"
              :settings="usersList"
              :entities="selectedAuthors"
            />
          </div>
          <div class="request-author__table h-100 col-12 col-md-6">
            <TableOverview
              kind="user"
              :settings="usersToAdd"
              :entities="allAuthors"
              :items="authorsPagination.items"
              :pagination-data="authorsPagination.pages"
              :get-page="getUserList"
            />
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import TableOverview from "@/views/Dashboard/Common/TableOverview/";
import Popup from "@/components/Popups/Popup";
const colors = require("/colors.config.json");
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "CreateModule",
  components: {
    Popup,
    TableOverview,
  },
  props: {
    modulePageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toggleStatus: true,
      allAuthors: [],
      updateKey: Date.now(),
      authorsPagination: {
        items: {
          begin: 0,
          end: 0,
          limit: 0,
          total: 0
        },
        pages: {
          current: 0,
          hasNext: false,
          hasPrev: false,
          next: 0,
          prev: 0,
          total: 0
        }
      }
    };
  },
  computed: {
    ...mapState("_module", {
      stateSingleData: "stateSingleData",
    }),
    ...mapGetters("helper", ["labels"]),
    usersToAdd() {
      return {
        header: [
          {
            headerLabel: this.labels.available_authors,
            headerColor: `${colors.gray_text}`,
            key: "username",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 60%",
            whiteSpace: "nowrap",
          },
        ],
        sortable: false,
        minTableWidth: "100%",
        actionsWidth: "0 0 40%",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.labels.add_to_module,
            variant: "btn-purple",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => this.toggleUserInModule(e),
            dynamicIdentifier: "_id",
            labelFn: this.actionLabel,
          },
        ],
      };
    },
    selectedAuthors() {
      return this.stateSingleData.authors;
    },
    usersList() {
      return {
        header: [
          {
            headerLabel: this.labels.linked_authors,
            headerColor: `${colors.gray_text}`,
            key: "username",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 100%",
            whiteSpace: "nowrap",
          },
        ],
        sortable: false,
        minTableWidth: "100%",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.labels.remove_author,
            variant: "btn-purple",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => this.toggleUserInModule(e),
            dynamicIdentifier: "_id",
          },
        ],
      };
    },
  },
  methods: {
    onModalClose() {
      this.$router.push({ name: "module-authors" });
    },
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("user", {
      getUsers: "getUsers",
      // getSelectedUsers: 'getSelectedUsers'
    }),
    ...mapActions("_module", {
      removeAuthor: "removeAuthor",
      addAuthor: "addAuthor",
    }),
    ...mapMutations("_module", {
      updateSingleModule: "updateSingleModule",
    }),
    isAuthor(id) {
      if (
        this.selectedAuthors?.length &&
        this.selectedAuthors.some((e) => e._id == id)
      ) {
        return true;
      }

      return false;
    },
    actionLabel(id) {
      if (this.isAuthor(id)) {
        return this.labels.remove_author;
      }

      return this.labels.add_to_module;
    },
    toggleUserInModule(user) {
      if (this.isAuthor(user._id)) {
        console.log("Removing author:", user);

        this.removeAuthor({
          id: this.stateSingleData._id,
          user: user._id,
        })
          .then((res) => {
            if (res.success) {
              let updatedModule = { ...this.stateSingleData };

              const indexOfAuthor = updatedModule.authors.findIndex(
                (p) => p._id == user._id
              );

              updatedModule.authors.splice(indexOfAuthor, 1);

              this.updateSingleModule(updatedModule);

              this.allAuthors.push({
                ...user,
              });

              this.addNotification({
                variant: "success",
                msg: ["author_deleted"],
                labels: this.labels,
                dismissSecs: 5,
              });
            } else if (res.error) {
              this.addNotification({
                variant: "danger",
                msg: res.error,
                labels: this.labels,
              });
            }
            return res;
          })
          .catch((err) => console.log(err));

        return;
      } else {
        console.log("Adding author:", user);

        this.addAuthor({
          id: this.stateSingleData._id,
          user: user._id,
        })
          .then((res) => {
            if (res.success) {
              let updatedModule = { ...this.stateSingleData };

              updatedModule.authors.push({
                _id: user._id,
                username: user.username,
              });

              const indexOfAvailableAuthor = this.allAuthors.findIndex(
                (p) => p._id == user._id
              );

              this.allAuthors.splice(indexOfAvailableAuthor, 1);

              this.updateSingleModule(updatedModule);

              this.addNotification({
                variant: "success",
                msg: ["author_added"],
                labels: this.labels,
                dismissSecs: 5,
              });
            } else if (res.error) {
              this.addNotification({
                variant: "danger",
                msg: res.error,
                labels: this.labels,
              });
            }
            return res;
          })
          .catch((err) => console.log(err));

        return;
      }
    },
    getUserList(page) {
      let idsToExclude = [];
      this.allAuthors = [];

      if (this.selectedAuthors?.length) {
        this.selectedAuthors.forEach((author) => {
          idsToExclude.push(author._id);
        });
      }

      let data = {
        page,
        adminRole: "author",
        excludeId: idsToExclude.join(),
      };

      return this.getUsers(data)
        .then((res) => {
          if (res.success) {
            res.data.data.forEach((element) => {
              this.allAuthors.push({
                username: element.username,
                _id: element._id,
              });
            });

            this.authorsPagination.items = res.data.items;
            this.authorsPagination.pages = res.data.pages;
          } else if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
          return res;
        })
        .catch((err) => console.log(err));
    },
    // getSelectedUsersMt() {
    //     this.getSelectedUsers( { page: 1, collection: 1 } )
    // 		.then( function ( data ) {
    // 			if( data ){
    //                 console.log(data, 'DATA')
    // 			}
    // 		} )
    // 		.catch( error => console.log( error ) );
    // }
  },
  mounted() {
    // this.getSelectedUsersMt();
    this.getUserList(1);
  },
};
</script>

<style lang="scss" scoped>
.request-author {
  &__list {
    border-right: 1px solid $light_gray;
  }

  &__list,
  &__table {
    overflow-y: auto;
  }
}

::v-deep {
  .modal-body {
    @media (min-width: 768px) {
      overflow-y: hidden;
      height: 600px;
    }
  }
  .formulate-input {
    &[data-classification="textarea"] {
      flex: 1;
      & textarea {
        height: 100%;
      }
    }
    &-wrapper,
    &-element {
      height: 100%;
    }
  }
  .overview {
    height: 100%;
    &__header {
      margin: 0;
    }
  }
  .base-card {
    &:not(.none) {
      box-shadow: unset;
      height: 100%;
      &:hover {
        box-shadow: unset;
      }
    }
    .table-card__thead {
      padding-top: 0;
    }
    .table-card-row.last {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  .modal-footer {
    display: none;
  }
  .table-card {
    &__tbody {
      height: calc(100% - 40px);
      overflow-y: auto;
    }
  }
}

.description-title {
  font-family: "Oswald", sans-serif;
  line-height: 1.5;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 10px;
}
</style>
